.stats {

  .stats-summary {
    .stats-header {
      display: flex;
      justify-content: space-between;
      font-weight: 700;

      div {
        line-height: 23px;
        margin: 20px 0;
      }
      .score {
        color: $orange;
      }
    }

    .stats-pane {
      padding: 25px 0 0;
      border-radius: 12px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
      background-image: linear-gradient(to bottom, #fff, #fafcff);
      overflow: hidden;
    }

    .stats-pane-header {
      padding: 0 25px;

      .seasons {
        display: flex;
        justify-content: flex-start;

        li {
          padding: 4px 0;
          margin-right: 5px;
          width: 55px;
          font-size: 12px;
          text-align: center;
          color: $orange;
          border-radius: 14.5px;
          border: solid 1px #ff8d03;
          transition: color .2s $QuadEaseOut, background .2s $QuadEaseOut;
          cursor: pointer;

          &.active {
            color: #fff;
            background-color: $orange;
          }
        }
      }

      .sold-products {
        .item-stats {
          display: flex;
          justify-content: space-between;
          margin: 25px 0 15px;

          .hyper-line {
            width: 0%;
            height: 4px;
            border-radius: 2px;
            background-color: $dark-blue;
          }

          .vuse-line {
            width: 0%;
            height: 4px;
            border-radius: 2px;
            background-color: $light-blue;
          }
        }

        .sold-items {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .item {
            display: flex;
            align-items: center;
            margin: 0 15px;
            font-weight: 700;

            &.item-vuse {
              color: $light-blue;

              .score {
                background-color: $light-blue;
              }
            }

            .score {
              width: 24px;
              height: 24px;
              margin-left: 8px;
              background-color: $dark-blue;
              text-align: center;
              line-height: 24px;
              color: #fff;
              font-size: 14px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .ranks-pane {
      padding: 5px 0 10px 0;
      text-align: center;
      background-color: #f4f5f9;

      h4 {
        margin-bottom: .5em;
      }

      p {
        font-size: 12px;
        padding: 0 25px;
      }

      .disclaimer {
        color: #817e7e;
      }

      .ranks-holder {
        width: 100%;
        padding: 10px 0;
        overflow-x: scroll;

        .ranks {
          display: flex;
          justify-content: space-between;
          width: 800px;
          padding: 0 25px;

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 72px;
            background-color: #fff;
            color: $dark-blue;
            text-align: center;
            font-weight: 700;
            border-radius: 12px;
            border: solid 2px #efefef;

            &.completed {
              border: solid 2px $dark-blue;
            }

            &.current {
              border: solid 2px $dark-blue;
              background-color: $dark-blue;
              color: #fff;
            }

            p {
              margin: .3em 0 0;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .history-section {
    margin-top: 15px;
    background-color: #fff;
    padding: 15px 25px 60px;
    text-align: center;

    h3 {
      margin: .5em 0;
    }

    table {
      width: 100%;
      text-align: left;
      font-size: 12px;

      tr {
        width: 100%;

        td {
          padding: 10px 0;
          border-bottom: solid 1px #efefef;
        }

        .date {
          color: #817e7e;
          width: 60px;
        }

        .name {
          padding-left: 5%;
          width: calc(100% - 100px);
        }

        .score {
          width: 40px;
          color: $orange;
          font-size: 14px;
          font-weight: 700;
          text-align: right;
        }
      }
    }
  }
}