.nav-trigger {
	z-index: 100;
	position: absolute;
	top: 48px;
	right: 25px;
	width: 32px;
	height: 32px;
	cursor: pointer;
}

.nav {
	z-index: 910;
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 32px;
	right: 0;
	height: calc(100% - 32px);
	width: 100%;
	padding: 25px 25px 25px 25px;
  background-color: $dark-blue;
  transform: translate(105%, 0);
  transition: transform, .7s $CubicEaseInOut;

  &.active {
  	transform: translate(0,0);
  }

  .close {
  	position: absolute;
  	top: 16px;
  	left: 25px;
  	cursor: pointer;
  }

  .profile-section {
  	padding-bottom: 10px;
  	border-bottom: 1px solid #d9deeb;
  }

  .nav-links {
  	padding: 20px 0 20px;
	  
	  .nav-item {
		  margin: 25px 0 30px;
		  display: block;
			color: #fff;
			font-size: 26px;
			font-weight: 600;
			text-align: center;
			text-decoration: none;
	  }
  }

  .nav-footer {
		position: absolute;
		bottom: 6%;
		left: 50%;
		margin-left: -25px;
	  padding-top: 10px;
	  font-weight: 700;
	  color: #fff;
	  text-align: center;
  }

  .avatar {
  	height: 90px;
  	width: 135px;
  	padding-left: 45px;
  	margin-top: 20px;
  }

  p {
  	font-size: 16px;
  	line-height: 1.5;

  	.profile-name {
  		font-weight: 700;
  	}
  }
}
