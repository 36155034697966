.activation-page {
  .page-header {
    .btn {
      margin-top: 30px;
    }
  }

  .activation-image {
    width: 100%;
    height: auto;
    max-width: 360px;
    border: 0;
    display: block;
    margin: 30px auto;
  }

  .quiz-section {
    padding-top: 20px;
    padding-bottom: 70px;
    margin-top: 30px;
    background-color: #fff;

    h2 {
      text-align: left;
      font-weight: 700;
    }

    .question-box {
      padding: 16px 23px 16px 24px;
      margin-bottom: 30px;
      border-radius: 12px;
      border: solid 1px #dfdfdf;

      .nr {
        position: relative;
        font-weight: 700;
        color: #ff8d03;

        &:after {
          content: "";
          position: absolute;
          width: 90%;
          height: 1px;
          background-color: #ff8d03;
          right: 0;
          top: 8px;
        }
      }

      .question {
        font-weight: 700;
      }

      .answers {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          margin: 10px 0 5px;
          padding: 0 5px;
          width: 48%;
          height: 76px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          border-radius: 12px;
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.04);
          border: solid 1px #efefef;
          cursor: pointer;
          font-size: 14px;
          font-weight: 700;
          text-align: center;
          background-size: cover;
          background-position: center;

          span {
            width: 100%;
          }

          &.active {
            border: solid 2px #001854;
          }

          .subtext {
            font-size: 12px;
            color: #817e7e;
            font-weight: 400;
          }
        }
      }
    }
  }

  .inactive {
    display: none;
  }

  .user-answer {
    .small {
      font-size: 12px;;
    }

    .right-answer {
      color: #43bd6f;
      font-weight: 700;
      line-height: 16px;
      display: flex;

      svg {
        margin-right: 10px;
      }
    }
  }
}