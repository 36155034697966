.new-sale {
  section {
    display: none;

    &.active {
      display: block;
    }
  }

  h1 {
    text-align: center;

    svg {
      position: relative;
      top: -8px;
      margin-left: 6px;
      cursor: pointer;
    }
  }

  .page-header {
    position: relative;
  }

  .info-box {
    position: absolute;
    top: calc( 100% + 10px );
    left: 0;
    width: 100%;
    padding: 20px 10px;
    margin: 0;
    z-index: 10;
    background-color: $blue;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut;

    &.open {
      opacity: 1;
      visibility: visible;
    }

    &:before {
      content: "";
      width: 7px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 10px;
      right: 10px;
      transform: rotate(-45deg);
    }

    &:after {
      content: "";
      width: 7px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 10px;
      right: 10px;
      transform: rotate(45deg);
    }
  }

  .scan-btn {
    position: relative;
    width: 248px;
    height: 248px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-weight: 700;
    margin: 50px auto 0;
    color: #fff;
    background-color: $orange;
    border-radius: 50%;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.16);
    z-index: 3;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 128px;
      height: 128px;
      border: #fff solid 1px;
      z-index: -2;
    }

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 128px;
      height: 128px;
      background-color: $orange;
      z-index: -1;
    }

    .scan-icon {
      margin-bottom: 15px;
      margin-top: 24px;
    }
  }

  .enter-link {
    margin: 25px 0;
    text-align: center;
    font-weight: 700;
    color: $blue;
    text-decoration: underline;
  }

  .earn-more-pane {
    margin-top: 50px;
    padding: 50px 25px 25px;
    background-color: $dark-blue;
    border-radius: 12px;
    color: #fff;
    text-align: center;

    p {
      margin-bottom: 50px;
    }

    .white-link {
      display: inline-block;
      margin-top: 15px;
      color: #fff;
      text-decoration: underline;
    }
  }

  #userPic {
    display: none;
  }

  .serial-box {
    padding: 25px 20px 25px 20px;
    margin-bottom: 40px;
    border-radius: 12px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
    text-align: center;
    font-weight: 700;
    background-color: #fff;

    p {
      margin: 0;
    }

    h4 {
      margin: .5em 0 0;
      font-size: 32px;
      letter-spacing: .2em;
      font-weight: 600;
    }
  }
}